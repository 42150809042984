<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton btnTitle="Add New" :showBtn="false" :showAddNew="true" title="Stock Transfer List"
                   @onClickAddNewButton="onClickAddNewButton"/>
      <div class="row match-height mt-1">
        <div class="col-12  col-xl-10 col-xxl-10">
          <DateQuerySetter @onClickGo="getStockTransfers"/>
        </div>
      </div>
    </div>

    <ListTable :transferList="transferList"/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination :offset="offset" @onPageChange="onPageChange" ref="pagination"/>
    </div>
  </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import ListTable from '@/components/molecule/procurement/inventory/stock/transfer/ListingTable'
import Pagination from '@/components/atom/Pagination'
import {inject, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import handleStock from "@/services/modules/stock";
import {useStore} from "vuex";

const $router = useRouter();
const $route = useRoute();
const $store = useStore();
const offset = ref(20)
const {fetchStockTransfers} = handleStock();
const transferList = ref([]);
const showError = inject('showError');

const onClickAddNewButton = () => {
    let params = $route.params;
    let query = $route.query;
    $router.push({name: 'stock-transfer-add', params: params, query: query})
}

function getQuery() {
    let query = '?company_id=' + $route.params.companyId
    query += '&offset=' + offset.value
    query += '&start_date=' + $route.query.start
    query += '&end_date=' + $route.query.end
    if ($route.query.page) query += '&page=' + $route.query.page
    return query
}

const getStockTransfers = () => {
  fetchStockTransfers(getQuery())
      .then(res => {
          if(res.date) {
              transferList.value = res.date.data
              setPagination(res.date)
          }else{
            transferList.value = [];
          }
      })
      .catch( err => {
          showError(err.response.message);
      })
}

function setPagination(data) {
    if(!data.data.length) return
    $store.commit('setPaginationData', {
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

async function onPageChange(page) {
    let routeQuery  = Object.assign({}, $route.query)
    routeQuery.page = page
    await $router.push({path: $route.path, query: routeQuery})
    await getStockTransfers();
}

onMounted(() => {
    getStockTransfers();
})

</script>
